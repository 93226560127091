import AsyncView from '../helpers/AsyncView';
import ProductItem from '../components/product/ProductItem';

const selectors = {
  container: '[data-product-recommendations]',
  productItem: '[data-product-item]',
};

export default class StaticProductRecommendations {
  constructor(section) {
    const sectionId = section.id;
    const { productId, customerIsWholesale } = section.data;
    const { limit } = section.data.settings;
    const container = document.querySelector(selectors.container);

    this._loadRecommendations(
      container,
      sectionId,
      productId,
      limit,
      customerIsWholesale,
    );
  }

  _loadRecommendations(
    container,
    sectionId,
    productId,
    limit,
    customerIsWholesale,
  ) {
    // eslint-disable-next-line no-magic-numbers
    const safetyLimit = limit * 2;
    const url = `${theme.routes.recommendations}?section_id=${sectionId}&limit=${safetyLimit}&product_id=${productId}`;

    AsyncView.load(url, { view: '' }).then(({ html }) => {
      const fragment = document.createRange().createContextualFragment(html);
      const recommendations = fragment.querySelectorAll(selectors.productItem);
      let count = 0;

      for (let i = 0; i < recommendations.length; i++) {
        const isWholesale = recommendations[i].dataset.isWholesale == 'true'; // Data attribute passed as a string
        if (customerIsWholesale) {
          // Show recs no matter what
          container.appendChild(recommendations[i]);
          count++;
        } else if (!isWholesale) {
          // If customer is not wholesale, only show non-wholesale products
          container.appendChild(recommendations[i]);
          count++;
        }
        if (count > limit) {
          break;
        }
      }

      const products = document.querySelectorAll(
        `${selectors.container} ${selectors.productItem}`,
      );

      products.forEach(product => new ProductItem(product));
    });
  }
}
