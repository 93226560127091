
import Filters from '../components/Filters';
import ProductItem from '../components/product/ProductItem';

export default class Collection {
  constructor(section) {
    const productItems = section.el.querySelectorAll('[data-product-item]');
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    new Filters(section);
  }
}
