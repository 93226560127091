import { useEffect, useState } from 'preact/hooks';

const useEmptyPortal = container => {
  const [emptied, setEmptied] = useState(false);

  if (!container) {
    return null;
  }

  useEffect(() => {
    if (!emptied) {
      container.innerHTML = '';
      setEmptied(true);
    }
  }, [emptied]);

  return emptied;
};

export default useEmptyPortal;
