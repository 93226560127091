import Flickity from 'flickity';
import debounce from '../helpers/debounce';
import 'flickity-imagesloaded';

const selectors = {
  carouselNav: '[data-text-carousel-nav]',
  carouselImage: '[data-text-carousel-image]',
  mainCarousel: '[data-text-carousel]',
};

const cssClasses = {
  selected: ['opacity-100', 'quote-carousel_nav-item--selected'],
  unselected: ['opacity-60'],
};

/**
 * Dynamic section for use throughout the site
 *
 * @export
 * @class DynamicQuoteSlider
 */
export default class DynamicQuoteSlider {
  constructor(section) {
    this.el = section.el;
    this.nav = section.el.querySelector(selectors.carouselNav);
    this.navItems = section.el.querySelectorAll(selectors.carouselImage);

    this.debounceWaitTime = 500; // Give the fonts half a second to load and render

    this._initCarousel();
    this._initNav();
    this._resizeCarousel();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.flickity = new Flickity(
      this.el.querySelector(selectors.mainCarousel),
      {
        adaptiveHeight: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: 'center',
        imagesLoaded: true,
        percentPosition: true,
      },
    );

    this.flickity.on('select', index => {
      const selectedClasses = cssClasses.selected;
      const unselectedClasses = cssClasses.unselected;
      const newThumb = this.navItems[index];

      this.navItems.forEach(el => {
        el.classList.remove(...selectedClasses);
        el.classList.add(...unselectedClasses);
      });
      newThumb.classList.add(...selectedClasses);
      newThumb.classList.remove(...unselectedClasses);
    });
  }

  _resizeCarousel() {
    onload = debounce(() => {
      this.flickity.resize();
    }, this.debounceWaitTime);
  }

  /**
   * Initialize clicking on thumbnail navigation
   */
  _initNav() {
    this.nav.addEventListener('click', event => {
      const navItems = Array.prototype.slice.call(this.nav.children);
      this.flickity.select(navItems.indexOf(event.target.parentElement));
    });
  }
}
