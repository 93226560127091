import Flickity from 'flickity/js/flickity';
import { pixelBreakpoints } from '../helpers';

const selectors = {
  carousel: '[data-tag-carousel]',
};

export default class StaticBlog {
  constructor(section) {
    this.el = section.el;

    if (this.el.querySelector(selectors.carousel)) {
      this._initCarousel();
    }
  }

  //`(max-width: ${breakpoints.lg}px)`

  _initCarousel() {
    if (matchMedia(`screen and (max-width: ${pixelBreakpoints.md})`).matches) {
      this.flickity = new Flickity(this.el.querySelector(selectors.carousel), {
        prevNextButtons: true,
        pageDots: false,
        cellAlign: 'left',
        percentPosition: true,
        freeScroll: true,
        contain: true,
        arrowShape:
          'M66.4779 79.4681C64.4484 81.5106 61.1578 81.5106 59.1283 79.4681L33.5221 53.6983C31.4926 51.6558 31.4926 48.3442 33.5221 46.3018L59.1283 20.5318C61.1578 18.4894 64.4484 18.4894 66.4779 20.5318C68.5074 22.5741 68.5074 25.8859 66.4779 27.9282L44.5465 50L66.4779 72.0716C68.5074 74.1141 68.5074 77.4256 66.4779 79.4681Z',
      });
    }
  }
}
