import DynamicBlogListings from '../scripts/sections/DynamicBlogListings';
import DynamicFeaturedCollection from '../scripts/sections/DynamicFeaturedCollection';
import DynamicFeaturedCollectionSlider from '../scripts/sections/DynamicFeaturedCollectionSlider';
import DynamicFeaturedProduct from '../scripts/sections/DynamicFeaturedProduct';
import DynamicImageSlider from '../scripts/sections/DynamicImageSlider';
import DynamicImagewFaq from '../scripts/sections/DynamicImagewFaq';
import DynamicQuoteSlider from '../scripts/sections/DynamicQuoteSlider';
import DynamicTextSlider from '../scripts/sections/DynamicTextSlider';
// Sections
import Sections from '../scripts/helpers/SectionManager';
import StaticAddresses from '../scripts/sections/StaticAddresses';
import StaticAnnouncementBar from '../scripts/sections/StaticAnnouncementBar';
import StaticBlog from '../scripts/sections/StaticBlog';
import StaticCollection from '../scripts/sections/StaticCollection';
import StaticFaq from '../scripts/sections/StaticFaq';
import StaticFooter from '../scripts/sections/StaticFooter';
import StaticHeader from '../scripts/sections/StaticHeader';
import StaticProduct from '../scripts/sections/StaticProduct';
import StaticProductRecommendations from '../scripts/sections/StaticProductRecommendations';
import StaticProductTabs from '../scripts/sections/StaticProductTabs';
import StaticSearch from '../scripts/sections/StaticSearch';

// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register(
  'static-collection',
  section => new StaticCollection(section),
);
sections.register('static-product', section => new StaticProduct(section));
sections.register(
  'static-product-tabs',
  section => new StaticProductTabs(section),
);
sections.register('static-addresses', section => new StaticAddresses(section));
sections.register(
  'static-announcement-bar',
  section => new StaticAnnouncementBar(section),
);
sections.register('static-blog', section => new StaticBlog(section));
sections.register('static-header', section => new StaticHeader(section));
sections.register('static-footer', section => new StaticFooter(section));
sections.register('static-faq', section => new StaticFaq(section));
sections.register('static-search', section => new StaticSearch(section));
sections.register(
  'dynamic-blog-listings',
  section => new DynamicBlogListings(section),
);
sections.register(
  'dynamic-featured-collection',
  section => new DynamicFeaturedCollection(section),
);
sections.register(
  'dynamic-featured-collection-slider',
  section => new DynamicFeaturedCollectionSlider(section),
);
sections.register(
  'dynamic-featured-product',
  section => new DynamicFeaturedProduct(section),
);
sections.register(
  'dynamic-text-slider',
  section => new DynamicTextSlider(section),
);
sections.register(
  'dynamic-quote-slider',
  section => new DynamicQuoteSlider(section),
);
sections.register(
  'dynamic-image-w-faq',
  section => new DynamicImagewFaq(section),
);

sections.register(
  'static-product-recommendations',
  section => new StaticProductRecommendations(section),
);

sections.register(
  'dynamic-image-slider',
  section => new DynamicImageSlider(section),
);

// Init preact
mountPreact();
