const selectors = {
  closeAnnouncementIcon: '[data-announcement-close]',
};
export default class StaticAnnouncementBar {
  constructor(section) {
    this.section = section;

    this._initAnnouncementBar();
  }

  _initAnnouncementBar = () => {
    const hideAnnouncementBar = sessionStorage.getItem('hide-announcement-bar');
    if (hideAnnouncementBar) {
      this.section.el.classList.add('hidden');
    } else {
      this.section.el.classList.remove('hidden');
    }

    const closeAnnouncementTrigger = this.section.el.querySelector(
      selectors.closeAnnouncementIcon,
    );
    if (closeAnnouncementTrigger) {
      closeAnnouncementTrigger.addEventListener(
        'click',
        this._closeAnnouncementBar,
      );
    }
  };

  _closeAnnouncementBar = () => {
    this.section.el.classList.add('hidden');
    sessionStorage.setItem('hide-announcement-bar', true);
  };
}
