import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import { formatMoney } from '@shopify/theme-currency';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartItem = ({ item }) => {
  const [error, setError] = useState();

  return (
    <div class="relative flex items-center border-b border-primary-1 py-5 md:py-6">
      {item.featured_image && item.featured_image.url && (
        <div class="mr-3 w-16">
          <a class="block aspect-square" href={item.url}>
            <img
              class="h-full w-full object-cover"
              alt={item.featured_image.alt}
              src={getSizedImageUrl(item.featured_image.url, 'medium')}
            />
          </a>
        </div>
      )}

      <div class="flex-1">
        <div class="md:items-middle md:flex">
          <div class="mr-3 flex-1">
            <a
              class="type-heading-6 mb-1 block text-primary-1 sm:mb-2"
              href={item.url}
            >
              {item.title}
            </a>
            {!item.product_has_only_default_variant && (
              <div class="type-body-2 mb-2 text-primary-1">
                {item.variant_title}
              </div>
            )}
            {/* Discounts */}
            {item.line_level_discount_allocations.length > 0 &&
              Object.entries(item.line_level_discount_allocations).map(
                ([key, value]) => (
                  <p key={key} class="mb-1 text-xs text-grey-2 sm:mb-2">
                    {value.discount_application.title} -{' '}
                    {formatMoney(value.amount, theme.moneyFormat)}
                  </p>
                ),
              )}
            {item.compare_at_price !== item.final_price &&
            item.compare_at_price ? (
              <div class="my-1 flex items-center space-x-3 sm:my-2">
                <div class="type-price text-xs">
                  <span class="sr-only">
                    {getTranslation('cart.discounted_price')}
                  </span>
                  {formatMoney(item.final_price, theme.moneyFormat)}
                </div>

                <div class="type-price-original text-xs">
                  <span class="sr-only">
                    {getTranslation('cart.original_price')}
                  </span>
                  <s>{formatMoney(item.compare_at_price, theme.moneyFormat)}</s>
                </div>
              </div>
            ) : (
              <div class="type-price my-1 text-xs sm:my-2">
                {formatMoney(item.final_price, theme.moneyFormat)}
              </div>
            )}

            {item.selling_plan_allocation && (
              <span class="type-button-small my-1 inline-flex items-center rounded-full bg-primary-1 px-2 py-1 text-white">
                <svg
                  className="mr-1 w-4"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3 3.3v4.2h.5m0 0a6.7 6.7 0 0 1 12.8 1.7M3.8 7.5h3.7m9.2 9.2v-4.2h-.5m0 0a6.7 6.7 0 0 1-12.8-1.7m12.8 1.7h-3.7"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
                {item.selling_plan_allocation.selling_plan.name}
              </span>
            )}

            {/* Properties */}
            {item.properties &&
              Object.entries(item.properties).map(
                ([key, val]) =>
                  val &&
                  !key.startsWith('_') && (
                    <p class="mb-1 text-xs sm:mb-2">
                      {key}:{' '}
                      {val.includes('/uploads/') ? (
                        <a href={val}>{val.split('/').pop()}</a>
                      ) : (
                        val
                      )}
                    </p>
                  ),
              )}
          </div>

          <div class="flex flex-1 flex-col-reverse md:items-end">
            <div class="mb-2 md:ml-4 md:mt-2">
              <div class="type-price">
                {formatMoney(item.final_line_price, theme.moneyFormat)}
              </div>
            </div>

            <QuantitySelector item={item} setError={setError} />
          </div>
        </div>
        <CartRemove item={item} />
      </div>

      {error && (
        <div class="type-overline absolute bottom-1 right-0 text-error">
          {getTranslation('cart.quantity_error', { quantity: error })}
        </div>
      )}
    </div>
  );
};

export default CartItem;
