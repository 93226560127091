import CartCrossSellProduct from './CartCrossSellProduct';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const CROSS_SELL_LIMIT = 3;

const CartCrossSell = () => {
  const { cart, isMiniCartOpen } = useCartContext();
  const [cartIds, setCartIds] = useState(null);
  const [crossSellItems, setCrossSellItems] = useState([]);

  useEffect(() => {
    setCartIds(cart.items.map(item => item.product_id));
  }, [cart.items]);

  useEffect(() => {
    if (isMiniCartOpen && cart && cart.items.length > 0) {
      setCrossSellItems([]);
      cart.items.forEach(item => {
        fetch(
          `${Shopify.routes.root}recommendations/products.json?product_id=${item.product_id}&limit=3&intent=complementary`,
        )
          .then(response => {
            return response.json();
          })
          .then(({ products }) => {
            if (products.length > 0) {
              products.forEach(product => {
                if (!cartIds.includes(product.id)) {
                  setCrossSellItems(prev => {
                    if (prev.length < CROSS_SELL_LIMIT) {
                      return [...prev, product];
                    }
                    return prev;
                  });
                }
              });
            }
          });
      });
    }
  }, [cart.items, isMiniCartOpen]);

  return crossSellItems && crossSellItems.length > 0 ? (
    <div class="relative py-8">
      <p class="type-heading-6 mb-4">{getTranslation('mini_cart.upsell')}</p>
      <div
        class="hide-scrollbar relative flex snap-mandatory overflow-hidden overflow-x-auto scroll-smooth"
        data-scroll-lock-scrollable
      >
        {(crossSellItems || []).map(item => (
          <CartCrossSellProduct key={item.id} product={item} />
        ))}
      </div>
    </div>
  ) : null;
};

export default CartCrossSell;
