import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const ShippingProgressBar = ({ freeShippingThreshold }) => {
  const { cart } = useCartContext();
  // eslint-disable-next-line no-magic-numbers
  const [progress, setProgress] = useState(0);
  const oneHundred = 100;

  useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    if (cart.total_price >= freeShippingThreshold) {
      setProgress(oneHundred);
    } else {
      setProgress((cart.total_price / freeShippingThreshold) * oneHundred);
    }
  }, [cart]);

  return (
    <div class="mt-3 w-full rounded-full bg-primary-1/30">
      <div
        style={`width:${progress}%`}
        class="h-3 rounded-full bg-primary-1 transition-width ease-in-out"
      ></div>
    </div>
  );
};

export default ShippingProgressBar;
