import { default as SiteLocaleEn } from '../../../../locales/en.default.json';

/**
 * Locale translation in javascript files
 *
 * @param {string} string the translation string to be used. eg: 'product.sale'
 * @param {object} replacements an object with each key being the string to be replaced and the value being what to replace it with.
 */
const getTranslation = (string, replacements) => {
  const languages = {
    en: SiteLocaleEn,
  };

  let translation = string
    .split('.')
    .reduce((acc, cur) => acc[cur], languages[Shopify.locale]);

  if (replacements) {
    Object.entries(replacements).forEach(([key, val]) => {
      translation = translation.replaceAll(`{{ ${key} }}`, val);
    });
  }

  return translation;
};

const getDataForCart = () => {
  const dataForCart = JSON.parse(
    document.querySelector('[data-type="for-cart"]').innerHTML,
  );

  return dataForCart;
};

/**
 * Return the id from a string
 * @param {string} string the string to be checked
 *
 */
const getIdfromGid = string => {
  const value = string.split('/').pop();
  return parseInt(value);
};

const mapEdgesToNodes = data => {
  if (!data?.edges) {
    return [];
  }

  return data.edges.map(edge => edge.node);
};

/**
 * This triggers an update on the react cart and upsells
 * @param {boolean} openMiniCart
 */
const updateReactCart = openMiniCart => {
  const preactApp = document.querySelector('#pxu-preact-app');
  preactApp.dispatchEvent(
    new CustomEvent('updateReactCart', { detail: { openMiniCart } }),
  );
};

export {
  getTranslation,
  getIdfromGid,
  mapEdgesToNodes,
  getDataForCart,
  updateReactCart,
};
