import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartTotals = () => {
  const {
    cart: { total_price, item_count, cart_level_discount_applications },
  } = useCartContext();

  return (
    <div class="mt-5 md:mt-6">
      {cart_level_discount_applications.length > 0 &&
        cart_level_discount_applications.map(discount => (
          <div class="body-2 mb-2 flex justify-between md:mt-6">
            <p>{discount.title}</p>
            <p>
              -{formatMoney(discount.total_allocated_amount, theme.moneyFormat)}
            </p>
          </div>
        ))}

      <div class="type-subtitle-1 mb-6 flex justify-between">
        <p>
          {getTranslation('cart.subtotal')} ({item_count} items){' '}
        </p>
        <p>{formatMoney(total_price, theme.moneyFormat)}</p>
      </div>
    </div>
  );
};

export default CartTotals;
