import { h } from 'preact';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { useCartContext } from '../context/CartContext';
import { useEffect, useState } from 'preact/hooks';

const ShippingProgressText = ({ freeShippingThreshold }) => {
  const { cart } = useCartContext();
  const [progress, setProgress] = useState(
    formatMoney(freeShippingThreshold),
    theme.moneyFormat,
  );

  useEffect(() => {
    const newPrice = formatMoney(
      freeShippingThreshold - cart.total_price,
      theme.moneyFormat,
    );
    setProgress(newPrice);
  }, [cart]);

  return (
    <p class="mt-1 text-primary-2">
      {cart.total_price >= freeShippingThreshold ? (
        <span>{getTranslation('mini_cart.free_shipping_reached')}</span>
      ) : (
        <span>{`${getTranslation(
          'mini_cart.free_shipping_pre',
        )} ${progress}`}</span>
      )}
    </p>
  );
};

export default ShippingProgressText;
