const selectors = {
  applyButton: '[data-filter-apply]',
  cancelButton: '[data-filter-cancel]',
  filterInput: '[data-filter-input]',
  closeButton: '[data-drawer-close]',
  openFilters: '[data-drawer-open]',
  expander: '[data-filter-expander]',
  expanderDrawer: '[data-filter-expander-drawer]',
  sorts: '[data-sort-by]',
};

export default class Filters {
  constructor(section) {
    this.el = section.el;

    this._submitForm = this._submitForm.bind(this);
    this._submitFormDrawer = this._submitFormDrawer.bind(this);
    this._openDrawer = this._openDrawer.bind(this);

    this._initFilters();
  }

  _initFilters() {
    const applyButtons = this.el.querySelectorAll(selectors.applyButton);
    const cancelButton = this.el.querySelector(selectors.cancelButton);
    const closeButton = this.el.querySelector(selectors.closeButton);
    const filters = this.el.querySelectorAll(selectors.filterInput);
    const openFiltersButton = this.el.querySelector(selectors.openFilters);
    const expanders = this.el.querySelectorAll(selectors.expander);
    const expandersDrawer = this.el.querySelectorAll(selectors.expanderDrawer);
    const sorts = this.el.querySelectorAll(selectors.sorts);

    if (filters.length > 0) {
      for (const filter of filters) {
        filter.onchange = e => {
          this._submitForm();
        };
      }
    }
    if (applyButtons.length > 0) {
      for (const button of applyButtons) {
        button.onclick = e => {
          if (button.dataset.filterApply == 'drawer') {
            this._submitFormDrawer();
          } else {
            this._submitFormDrawer();
          }
        };
      }
    }
    if (expanders.length > 0) {
      for (const expander of expanders) {
        expander.onclick = e => {
          this._expand(e);
        };
      }
    }
    if (expandersDrawer.length > 0) {
      for (const expander of expandersDrawer) {
        expander.onclick = e => {
          this._expandFromDrawer(e);
        };
      }
    }
    if (sorts.length > 0) {
      for (const sort of sorts) {
        sort.onchange = e => {
          if (e.target.dataset.sortBy == 'drawer') {
            this._submitFormDrawer();
          } else {
            this._submitForm();
          }
        };
      }
    }
    if (cancelButton) {
      cancelButton.addEventListener('click', this._closeDrawer);
    }
    if (closeButton) {
      closeButton.addEventListener('click', this._closeDrawer);
    }
    if (openFiltersButton) {
      openFiltersButton.addEventListener('click', this._openDrawer);
    }
  }

  _submitForm() {
    document.filterForm.submit();
  }

  _submitFormDrawer() {
    document.filterFormDrawer.submit();
  }

  _openDrawer() {
    document.getElementById('drawer').style.transform = 'translateX(0)';
    const bodyElement = document.querySelector('.template-collection');
    bodyElement.classList.add('filter-drawer-open');
  }

  _closeDrawer() {
    document.getElementById('drawer').style.transform = 'translateX(100%)';
    const bodyElement = document.querySelector('.template-collection');
    bodyElement.classList.remove('filter-drawer-open');
  }

  _expand(e) {
    e.preventDefault();
    const filterName = e.target.id.split('-')[1];
    const divToExpand = document.getElementById('hidden-filter-' + filterName);
    const expanderLabel = document.getElementById('expander-' + filterName);
    if (expanderLabel.innerHTML.trim() == 'Show more') {
      expanderLabel.innerHTML = 'Show less';
      divToExpand.style.display = 'flex';
    } else {
      expanderLabel.innerHTML = 'Show more';
      divToExpand.style.display = 'None';
    }
  }

  _expandFromDrawer(e) {
    e.preventDefault();
    const filterName = e.target.id.split('-')[1];
    const divToExpand = document.getElementById(
      'hidden_drawer-filter-' + filterName,
    );
    const expanderLabel = document.getElementById(
      'expander_drawer-' + filterName,
    );
    if (expanderLabel.innerHTML == 'Show more') {
      expanderLabel.innerHTML = 'Show less';
      divToExpand.style.display = 'flex';
    } else {
      expanderLabel.innerHTML = 'Show more';
      divToExpand.style.display = 'None';
    }
  }
}
