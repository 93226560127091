import ProductItem from '../components/product/ProductItem';

export default class DynamicFeaturedCollection {
  constructor(section) {
    // Init product items
    const productItems = section.el.querySelectorAll('[data-product-item]');
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });
  }
}
