import debounce from '../helpers/debounce';
import { unitlessBreakpoints } from '../helpers';

const selectors = {
  title: '[data-tab-title]',
  content: '[data-tab-content]',
};

/**
 * Controller for a quantity input
 *
 * @export
 * @class Quantity
 */
export default class Tabs {
  /**
   * Creates an instance of Quantity.
   * @param {HTMLElement} el Container element / scope
   * @param {Object} [config={}] Config object for callbacks and settings
   */
  constructor(el) {
    this.el = el;
    this.tabTitles = this.el.querySelectorAll(selectors.title);
    this.tabContents = this.el.querySelectorAll(selectors.content);

    // If input doesnt exist, return
    if (!this.el.querySelector(selectors.title)) {
      return;
    }

    this._activateTab = this._activateTab.bind(this);

    this._initTabs();

    this._positionTitles();
    window.onresize = debounce(() => {
      // eslint-disable-next-line no-unused-expressions
      this._positionTitles;
    });
  }

  _initTabs() {
    this.tabTitles.forEach((title, i) => {
      title.classList.add('tab-title');
      title.addEventListener('click', this._activateTab);
      if (i === 0) {
        title.classList.add('tab-title--selected');
      }
    });

    this.tabContents.forEach((content, i) => {
      content.classList.add('tab-content');
      if (i === 0) {
        content.classList.add('tab-content--selected');
      }
    });
  }

  _positionTitles() {
    const breakpointNumber = unitlessBreakpoints.lg;
    if (window.innerWidth < breakpointNumber) {
      this.tabTitles.forEach((title, i) => {
        title.style.removeProperty('left');
      });
    } else {
      this.tabTitles.forEach((title, i) => {
        const width = title.offsetWidth;
        const nextTitle = this.tabTitles[i + 1];
        if (nextTitle) {
          nextTitle.style.left = `${width}px`;
        }
      });
    }
  }

  _activateTab(e) {
    const target = e.target.dataset.tabTitle;
    if (!target) {
      return;
    }
    e.preventDefault();
    // deactivate other tabs
    const oldTitle = this.el.querySelector(`.tab-title--selected`);
    const oldContent = this.el.querySelector(`.tab-content--selected`);
    oldTitle.classList.remove('tab-title--selected');
    oldContent.classList.remove('tab-content--selected');
    // activate new one
    const newTitle = this.el.querySelector(`[data-tab-title="${target}"]`);
    const newContent = this.el.querySelector(`[data-tab-content="${target}"]`);
    newTitle.classList.add('tab-title--selected');
    newContent.classList.add('tab-content--selected');
    // update focus
  }

  /**
   * Detach event handlers (in case this is used in a section)
   */
  unload() {
    this.tabTitles.forEach(title => {
      title.removeEventListener('click');
    });
  }
}
