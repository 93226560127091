import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CartCrossSell from './CartCrossSell';
import ShippingProgressText from '../shared-components/ShippingProgressText';
import useEmptyPortal from '../hooks/useEmptyPortal';
import { createPortal } from 'preact/compat';
import { useCartContext } from '../context/CartContext';
import { getDataForCart, getTranslation } from '../helpers/utilities';

import { Fragment, h } from 'preact';

const CartPageEl = document.getElementById('preact-full-cart');

const CartPage = () => {
  const { cart } = useCartContext();
  const emptied = useEmptyPortal(CartPageEl);
  const dataForCart = getDataForCart();

  if (!emptied) {
    return null;
  }

  return createPortal(
    <Fragment>
      <h1 class="type-heading-4 text-primary-2">
        {getTranslation('mini_cart.title')}
      </h1>
      {!dataForCart.customerIsWholesale && dataForCart.showFreeShippingBar && (
        <ShippingProgressText
          freeShippingThreshold={dataForCart.freeShippingThreshold}
        />
      )}
      <div data-cart-items>
        {cart.items && cart.items.length > 0 ? (
          cart.items.map(item => <CartItem key={item.id} item={item} />)
        ) : (
          <CartEmpty />
        )}
      </div>

      <CartCrossSell />

      {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}
    </Fragment>,
    CartPageEl,
  );
};

export default CartPage;
