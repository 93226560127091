import ProductForm from './ProductForm';
import quickShop from './ProductQuickShop';

const selectors = {
  quickShopTrigger: '[data-quickshop-trigger]',
};

/**
 * A single product item in a collection / list of products
 *
 * @export
 * @class ProductItem
 */
export default class ProductItem {
  /**
   * Creates an instance of ProductItem.
   * @param {HTMLElement} el
   */
  constructor(el) {
    this.el = el;
    this._initQuickShop();
    this.productForm = new ProductForm(this.el);
  }

  /**
   * Bind quickshop to a trigger
   */
  _initQuickShop() {
    const trigger = this.el.querySelector(selectors.quickShopTrigger);

    if (!trigger) {
      return;
    }

    trigger.addEventListener('click', () => {
      const { productUrl, productHash } = this.el.dataset;

      quickShop.open(productUrl, productHash);
    });
  }
}
