import Flickity from 'flickity';
import { pixelBreakpoints } from '../../helpers';
import 'flickity-imagesloaded';

const selectors = {
  productNav: '[data-product-nav]',
  productNavInner: '[data-product-nav-inner]',
  productImage: '[data-product-image]',
  mainCarousel: '[data-image-carousel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(productContainer) {
    this.el = productContainer;
    this.nav = productContainer.querySelector(selectors.productNav);
    this.navInner = this.nav.querySelector(selectors.productNavInner);
    this.navItems = this.nav.querySelectorAll(selectors.productImage);

    this._initCarousel();
    this._initNav();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    const options = {
      prevNextButtons: true,
      pageDots: false,
      cellAlign: 'center',
      imagesLoaded: true,
      percentPosition: true,
      arrowShape:
        'M66.4779 79.4681C64.4484 81.5106 61.1578 81.5106 59.1283 79.4681L33.5221 53.6983C31.4926 51.6558 31.4926 48.3442 33.5221 46.3018L59.1283 20.5318C61.1578 18.4894 64.4484 18.4894 66.4779 20.5318C68.5074 22.5741 68.5074 25.8859 66.4779 27.9282L44.5465 50L66.4779 72.0716C68.5074 74.1141 68.5074 77.4256 66.4779 79.4681Z',
    };

    if (matchMedia(`screen and (max-width: ${pixelBreakpoints.lg})`).matches) {
      options.prevNextButtons = false;
      options.pageDots = true;
    }

    this.flickity = new Flickity(
      this.el.querySelector(selectors.mainCarousel),
      options,
    );

    this.flickity.on('select', index => {
      const selectedClasses = ['ring-primary-1'];
      const newThumb = this.navItems[index];

      this.navItems.forEach(el => {
        el.classList.remove(...selectedClasses);
      });
      newThumb.classList.add(...selectedClasses);

      this._scrollNav(newThumb);
    });
  }

  /**
   * Initialize clicking on thumbnail navigation
   */
  _initNav() {
    this.nav.addEventListener('click', event => {
      const navItems = Array.prototype.slice.call(this.navInner.children);
      this.flickity.select(navItems.indexOf(event.target.parentElement));
    });
  }

  /**
   * Scrolls nav to show currently selected thumbnail in view
   * @param {Element} newThumb newly selected thumbnail element
   */
  _scrollNav(newThumb) {
    // Determine whether the thumbnails are horizontal or vertical
    const navAxis = window.matchMedia(`(max-width: ${pixelBreakpoints.lg})`)
      .matches
      ? 'left'
      : 'top';

    // Gets the position of the beginning of the nav element
    const navStart = this.nav.getBoundingClientRect()[navAxis];

    // Gets the size of the nav element
    const navSize =
      navAxis === 'top' ? this.nav.clientHeight : this.nav.clientWidth;

    // Gets the size of the newly selected thumbnail
    const thumbSize =
      navAxis === 'top' ? newThumb.scrollHeight : newThumb.scrollWidth;

    // Gets the offset of the newly selected thumbnail relative to the nav
    const thumbOffset = Math.abs(
      navStart - newThumb[`${navAxis === 'top' ? 'offsetTop' : 'offsetLeft'}`],
    );

    // Scrolls the nav so that the selected thumbnail is centered
    this.nav.scroll({
      // eslint-disable-next-line no-magic-numbers
      [navAxis]: thumbOffset - (navSize - thumbSize) / 2,
      behavior: 'smooth',
    });
  }

  /**
   * Handler for swapping images when the variant changes
   *
   * @param {Object} variant The currently selected variant
   * @public
   */
  change(variant) {
    this.navItems.forEach((element, index) => {
      const { imageVariants } = element.dataset;

      // Bail if array of variants for this image
      // doesn't contain currently selected variant id
      if (!imageVariants.split(',').includes(String(variant.id))) {
        return;
      }

      this.flickity.select(index);
    });
  }
}
