import { h } from 'preact';
import { useCartContext } from '../context/CartContext';
import { useState } from 'preact/hooks';
import { getTranslation } from '../helpers/utilities';
import { formatMoney } from '@shopify/theme-currency';

const CartCrossSellProduct = ({ product }) => {
  if (!product) {
    return null;
  }

  const { addLineItem } = useCartContext();
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const productImage = product.images[0] ? product.images[0] : '';

  if (!selectedVariant || !selectedVariant.id) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();
    addLineItem(event);
  };

  return (
    <div
      class="mr-2 flex w-72 shrink-0 origin-center snap-start items-center overflow-hidden rounded-3xl border border-primary-1 bg-white py-2 pl-4 pr-6 md:mr-4 md:w-96"
      data-product-id={product.id}
    >
      <div class="mr-3 h-11 w-11">
        <img src={productImage} class="h-full w-full object-cover" />
      </div>
      <div class="flex h-full flex-1 flex-col justify-center">
        <h4 class="type-heading-6 mb-2 text-primary-1">{product.title}</h4>
        <div class="type-price">
          {formatMoney(selectedVariant.price, theme.moneyFormat)}
        </div>
        {selectedVariant.compare_at_price > selectedVariant.price && (
          <div class="flex items-center">
            <span class="type-price mr-3 text-xs">
              <span class="sr-only">
                {getTranslation('cart.discounted_price')}
              </span>
              {/* This is a temporary measure, due to graphql returning price in decimals and the formatMoney function using cents. Eventually Upsell here should use a product metafield. */}
              {formatMoney(selectedVariant.price, theme.moneyFormat)}
            </span>
            <span class="type-price-original text-right text-xs">
              <span class="sr-only">
                {getTranslation('cart.original_price')}
              </span>
              <s>
                {formatMoney(
                  selectedVariant.compare_at_price,
                  theme.moneyFormat,
                )}
              </s>
            </span>
          </div>
        )}
      </div>
      <div class="flex items-center">
        <form method="post" action="/cart/add" onSubmit={handleSubmit}>
          <input type="hidden" name="form_type" value="product" />
          <input type="hidden" name="utf8" value="✓" />
          <input type="hidden" name="id" value={selectedVariant.id} />
          <input type="hidden" name="quantity" value="1" />

          {product.variants.length > 1 && (
            <select
              name="variant"
              onChange={e => setSelectedVariant(e.target.value)}
            >
              {product.variants.map((name, id, index) => {
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
            </select>
          )}

          <button
            class="button button--primary md:ml-4"
            disabled={!selectedVariant.available}
          >
            {selectedVariant.available
              ? getTranslation('mini_cart.add_to_cart')
              : getTranslation('mini_cart.sold_out')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CartCrossSellProduct;
