import Flickity from 'flickity';
import 'flickity-imagesloaded';
import debounce from 'just-debounce';

const selectors = {
  carousel: '[data-blog-carousel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class DynamicBlogListings {
  constructor(section) {
    this.el = section.el;

    this._initCarousel();
  }

  /**
   * Initialize carousel
   */
  _initCarousel() {
    const options = {
      adaptiveHeight: true,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: 'center',
      imagesLoaded: true,
      wrapAround: true,
    };

    // Initialize
    if (matchMedia('screen and (max-width: 1024px)').matches) {
      this.flickity = new Flickity(
        this.el.querySelector(selectors.carousel),
        options,
      );
    }

    onresize = debounce(() => {
      if (matchMedia('screen and (max-width: 1024px)').matches) {
        // When resizing from large to small, create a carousel if there isn't one
        if (!this.flickity) {
          this.flickity = new Flickity(
            this.el.querySelector(selectors.carousel),
            options,
          );
        }
      } else if (this.flickity) {
        // When resizing from small to large, destroy the carousel if there is one
        this.flickity.destroy();
      }
    });
  }
}
