import ProductItem from '../components/product/ProductItem';

import Flickity from 'flickity';

import 'flickity-imagesloaded';

const selectors = {
  mainCarousel: '[data-featured-collection-carousel]',
};

/**
 * Dynamic section for use throughout the site
 *
 * @export
 * @class DynamicFeaturedCollectionSlider
 */

export default class DynamicFeaturedCollectionSlider {
  constructor(section) {
    // Init product items
    const productItems = section.el.querySelectorAll('[data-product-item]');
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    this.el = section.el;

    this._initCarousel();
    this._resizeCarousel();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.flickity = new Flickity(
      this.el.querySelector(selectors.mainCarousel),
      this._getSettings(),
    );

  }

  // Get the settings from the script with the appropiate section id.
  _getSettings() {
    

    const sectionSettings = this.el.querySelector('script[data-section-id]');
    const defaultSettings = {
      adaptiveHeight: true,
      //prevNextButtons: false,
      pageDots: false,
      //cellAlign: 'left',
      imagesLoaded: true,
      percentPosition: true,
      watchCSS: false,
    };

    const customizableSettings = {
      freeScroll: sectionSettings.getAttribute('data-section-free-scroll') === 'true',
      wrapAround: sectionSettings.getAttribute('data-section-wrap-around') === 'true',
      prevNextButtons: sectionSettings.getAttribute('data-section-prevNextButtons') === 'true',
    };    

    var breakpointOptions = {
      cellAlign: 'left' 
    };
    if ( matchMedia('screen and (max-width: 519px)').matches ) {
      breakpointOptions.cellAlign =  'center';
    }
    const breakpointSettings = breakpointOptions;

    return {
      ...defaultSettings,
      ...customizableSettings,
      ...breakpointSettings,
    };
  }

  _resizeCarousel() {
    onload = () => {
      this.flickity.resize();
    };
  }
}
